import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css';
import SolanaWallets from "solana-wallets-vue";
import './assets/styles/main.css'
// You can either import the default styles or create your own.
import "solana-wallets-vue/styles.css";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import router from './router'

import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,

} from "@solana/wallet-adapter-wallets";

const walletOptions = {
  wallets: [
    new PhantomWalletAdapter(),
    new SolflareWalletAdapter({ network: WalletAdapterNetwork.Devnet }),

  ],
  autoConnect: true,
};

createApp(App)
  .use(SolanaWallets, walletOptions)
  .use(router) // Use the router
  .mount('#app');