<!-- ErrorModal.vue -->
<template>
    <transition name="fade">
      <div v-if="visible" class="tx-modal">
        <div class="content">
          <p class="font-mono text-sm">Transaction ID: {{ message }}</p>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    data() {
      return {
        visible: false,
        message: '',
        timer: null,
      };
    },
    methods: {
      showTX(message) {
        this.message = message;
        this.visible = true;
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(this.close, 6000); // Auto-close after 10 seconds
      },
      close() {
        this.visible = false;
        if (this.timer) clearTimeout(this.timer);
      }
    }
  };
  </script>
  
  <style scoped>
  .tx-modal {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: #f7ff67;
    color: #72611c;
    border: 1px solid #f5eec6;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  .error-modal .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    color: white;


  }
  .error-modal button {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    padding-left: 30px;
    padding-right: 10px;
    font-size: larger

  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>
  