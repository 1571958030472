<template>
  <div class="">
       
      <div v-if="initialized">
        <div v-if="loading">
          <ul class="grid gap-x-0 grid-cols-1 xs:grid-cols-3   sm:grid-cols-5 lg:grid-cols-6 2xl:grid-cols-9 sm:gap-x-8  gap-y-12 pb-4 ">
            <li v-for="i in 24" :key="i"  class=" bg-slate-200/20 animate-pulse mx-auto rounded-lg">
              <div class="w-28 h-36">
                <div class="h-36 overflow-hidden">
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div v-else>
          <div class="w-full  flex justify-center pb-4">
            <div class="sm:flex justify-center w-full">
              <h1 class="outfit-400 pr-5 pt-1">Check NFT Eligibility by MINT ID: </h1>
              <input
              type="text"
              v-model="searchQuery"
              @input="LUTNFTcheck(searchQuery)"
              placeholder="Search..."
              class="w-[200px] px-2 py-1 text-md border rounded-lg focus:outline-none bg-gray-600 text-white focus:ring-2 focus:ring-blue-500"
            />
            </div>
          </div> 
          <div class="w-full text-center pb-4">
            <p class="text-green-300">{{searchResult}}</p>
            <p class="text-red-300">{{searchError}}</p>
            <p v-if="warning" class="text-gray-300 text-sm pt-4">Very rarely, burning can fail if the metadata is corrupted</p>
          </div> 
         
          <div v-if="nfts.length === 0" class="pt-4">No NFTs found for your account, try reloading the page if this is not expected</div>
          <div v-if="nfts.length !== 0" class=" grid grid-cols-3 w-full mb-2">
            <div class="w-full"></div>
            <div class=" w-full text-center">Select NFTs to Burn </div>
            <div class="w-full flex justify-end"><button class="  mr-4" @click="refreshInventory"><svg class="h-6 w-6 text-white "  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -5v5h5" />  <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 5v-5h-5" /></svg></button></div>
          </div>

          <ul class="grid gap-x-0 grid-cols-1 xs:grid-cols-3   sm:grid-cols-5 lg:grid-cols-6 2xl:grid-cols-9 sm:gap-x-8 gap-y-12 pb-12">
            <li
              v-for="(nft) in pagenfts"
              :key="nft.fetched.pubkeyAdded"
              class="relative bg-gradient-to-r  from-slate-700/50 to-slate-500/30 w-24 sm:w-full rounded-bl-md rounded-br-md mx-auto border-2 border-white/20"
            >
              <div class="group h-28 w-full overflow-hidden relative">
                <img
                  :src="nft.fetched.image ? nft.fetched.image : '/couldnot.png'"
                  height="130px"
                  class="overflow-hidden transition duration-300 ease-in-out group-hover:opacity-30"
                  alt="NFT image"
                />
                <button
                  class="absolute inset-0 flex items-center justify-center opacity-0 transition duration-100 ease-in-out group-hover:opacity-100"
                  @click="burnMINT(nft)"
                >
                  <span class="px-4 py-2 bg-gradient-to-r from-orange-400 to-red-500 text-white rounded">Burn</span>
                </button>
              </div>
              <div>
                <p v-if="nft" class="outfit-400 text-xs font-semibold py-3 outfit-300 font-semibold ">
                  {{ nft.metadata.data.data.name === '' ? 'mintID: '+nft.fetched.pubkeyAdded.slice(0, 5) : nft.metadata.data.data.name }}
                </p>
              </div>
            </li>
          </ul>

            <div v-if="!loading" class="flex  gap-4 justify-center pt-4 xl:pt-8 pb-12">
              <button class="" @click="prevPage" :hidden="currentPage === 1">Prev</button>
              <div v-for="(n,index) in numPages" :key="index" >
                <div v-if="index+1 == currentPage" >
                  <button disabled class="p-1  w-9 rounded-sm bg-white/40 hover:bg-white/50">
                    {{ index + 1 }} 
                  </button>  
                </div>
                <div v-else>
                  <button @click="choosePage(index)" class="p-1 bg-white/10 w-9 rounded-sm hover:bg-white/40">
                    {{ index + 1 }} 
                  </button>  
                </div>           
              </div>
              <button @click="nextPage" :hidden="currentPage * pageSize >= nfts.length">Next</button>

            </div>
        </div>
      </div>
      <div v-else-if="!initialized && loadinginitialized">
        <div role="status">
          <svg aria-hidden="true" class="inline w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <span class="sr-only">Loading...</span>
      </div>
      <div class="pt-6">Loading User Token Account...</div>
      </div>
      <div v-else>
        <div v-if="initializeloadingaction">
          <svg aria-hidden="true" class="inline w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <p class="flex justify-center pt-4">Initializing token account could take 1-2 minutes (Try refreshing the page if it takes too long)</p>
        </div>
        <div v-else>
        <button
          class=" w-36 h-12 bg-gradient-to-r from-orange-400 to-red-500 opacity-85 rounded-md hover:opacity-100"
          @click="initializeUser()"
        >
          Initialize Account
        </button>
        <p class="flex justify-center pt-4">You must initialize your account once to start burning</p>

        </div>
      </div>

      <ErrorModal ref="errorModal" />
      <TxModal ref="txModal" />

  </div>
  </template>
  
  <script>
import { getNftsForOwner, fetchNftMetadata,burnNFT,initializeUser,checkifinitialized,checkIfValidNFT } from '@/services/solana';
import ErrorModal from './ErrorModal.vue';
import TxModal from './TXModal.vue';

  export default {
    components:{ErrorModal,TxModal},

    data() {
      return {
        nfts: [],
        initialized: false,
        loadinginitialized:true,
        pagenfts:[],
        loading: true,
        ownerAddress: 'B', // Replace with the actual wallet address
        currentPage: 1,
        pageSize:27,
        numPages:[],
        initializeloadingaction:false,
        searchResult: '',
        searchError: '',
        warning: false,
      };
    },
    async created() {
        try {
          let initialized = await checkifinitialized()
          console.log(initialized)
          console.log(this.loadinginitialized)
          this.initialized = initialized
          this.loadinginitialized=false
          this.refreshInventory()

        } catch (error) {
            console.error('Error fetching NFTs:', error);
        }
    },

    /*watch: {
        paginatedNfts: {
            immediate: true,
            async handler(newPageNfts) {
            
                for (const [index, nft] of newPageNfts.entries()) {
                    if (!nft.metadata) {
                        const metadata = await fetchNftMetadata(nft.account.data.parsed.info.mint);
                        this.$set(this.nfts, (this.currentPage - 1) * this.pageSize + index, { ...nft, metadata });
                    }
                    if(this.nfts.length == 5){
                        this.loading = false;
                    }
                }
            }
        }
    },*/
    methods: {
      async triggerError(error) {
        if(error){
          this.$refs.errorModal.showError(error);

        }
        else{
          this.$refs.errorModal.showError('An unexpected error occurred.');
        }
    },
    async LUTNFTcheck(mint){
      this.searchError = ""
      this.searchResult = ""
      this.warning = false
      if(mint == ""){return}
      if(mint.length != 44){
        this.searchError = "Invalid NFT Mint Address Length"
        return
      }
      try{
      let response = await checkIfValidNFT(mint)
      if(response == "NFT is eligible for burning."){
        this.warning = true
        this.searchResult == response
      }
      this.searchResult = response
      }catch(e){
        this.searchError = "Sorry this NFT is not accepted"
      }

    },
    async triggertx(tx) {
        if(tx){
          this.$refs.txModal.showTX(tx);

        }
        else{
          this.$refs.errorModal.showError('An unexpected error occurred.');
        }
    },
      async burnMINT(mint){
        try {
          const response = await burnNFT(mint);
          this.triggertx(response)
        }catch (error) {
            console.error('Error fetching NFTs:', error);
            this.triggerError(error)
        }
      },
      async checkCondition() {
    // This is the function that will be called every 10 seconds
    try {
      const isInitialized = await this.checkifinitialized();
      return isInitialized; // Assuming checkifinitialized returns true or false
    } catch (error) {
      console.error('Error checking condition:', error);
      this.triggerError(error);
      return false;
    }
  },
      startCheckingCondition() {
        const checkInterval = 10000; // 10 seconds
        const intervalId = setInterval(async () => {
          const conditionMet = await this.checkifinitialized();
          if (conditionMet) {
            this.initializeloadingaction = false
            this.initialized = true
            clearInterval(intervalId); // Stop the interval when condition is met

            console.log('Condition met, stopping interval');
          }
        }, checkInterval);
      },
      async checkifinitialized(){
        try{
          const response = await checkifinitialized()
          console.log(response)
          return response

        }catch(e){
          console.log(e)
          //this.triggerError(e)
        }
      },
      async initializeUser(){
        try {
          this.initializeloadingaction = true;
          const response = await initializeUser();
          this.startCheckingCondition()
          //this.initialized = response
          console.log(response,"RESPONSE")
        }catch (error) {
            console.error('Error Initializing Account:', error);
    //        this.triggerError('Error Initializing Account:'+error)
        }
      },
        async fetchPaginatedNfts() {
            try {
                this.loading = true;
                const start = (this.currentPage - 1) * this.pageSize;
                const end = this.currentPage * this.pageSize;
                let targetNFTs = this.nfts.sort((a, b) => {
                    const mintA = a.account.data.parsed.info.mint.toLowerCase();
                    const mintB = b.account.data.parsed.info.mint.toLowerCase();
                    if (mintA < mintB) return -1;
                    if (mintA > mintB) return 1;
                    return 0;
                }).slice(start, end);                
                this.pagenfts = [];

                const fetchMetadataPromises = targetNFTs.map(async (nft) => {
                if (!nft.metadata) {
                    const metadata = await fetchNftMetadata(nft.account.data.parsed.info.mint);
                       // console.log("inside page nfts metadata:",metadata);
                        this.pagenfts.push(metadata);

                }
                });

                await Promise.all(fetchMetadataPromises);
                //console.log(this.pagenfts,"PAGE NFTS")
            } catch (error) {
                console.error('Error fetching paginated NFTs:', error);
                //this.triggerError('Error fetching paginated NFTs:'+error)

            } finally {
                this.loading = false;
            }
        },
     nextPage() {
      if (this.currentPage * this.pageSize < this.nfts.length) {
        this.currentPage++;
        this.fetchPaginatedNfts();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchPaginatedNfts();
      }
    },
    choosePage(page) {
        this.currentPage = page+1;
        this.fetchPaginatedNfts();
    },
    async refreshInventory(){
      try{
            const nftAccounts = await getNftsForOwner(this.ownerAddress);
            nftAccounts.sort((a, b) => {
                const mintA = a.account.data.parsed.info.mint.toLowerCase();
                const mintB = b.account.data.parsed.info.mint.toLowerCase();
                if (mintA < mintB) return -1;
                if (mintA > mintB) return 1;
                return 0;
            });
            //console.log(nftAccounts,"ALLNFTS ACCOUNTS");
            this.nfts = nftAccounts;
            const numpages2 = Math.ceil(nftAccounts.length/(this.pageSize))
            //console.log(numpages2)
            let arr = Array.from({ length: numpages2 }, () => numpages2);
            this.numPages = arr
            //console.log(this.numPages)
            this.fetchPaginatedNfts();
      }catch(e){
        console.error('Error fetching NFTs:', e);

      }

    }
    },
    
  };
  </script>
  
  <style>
  /* Add your styles here */
  </style>
  