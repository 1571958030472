<template>
    <div class="w-full fade-in-image">
    <div class="xs:px-12 sm:px-12 lg:px-[15%] ">
  
      <div class="">
        <div class="flex gap-3 grid-cols-3 justify-center xl:justify-between sm:gap-8   text-[17px] sm:text-[22px] pb-3">
          <div class="flex justify-center">
            <router-link to="/" ><div class="hidden sm:block">      
                <img src="../assets/peepo-trans.gif" class=" " width="40px"   alt="ArtPiece">          
            </div></router-link>
            <router-link to="/" ><div class="hidden sm:block mt-[10px] text-[22px] outfit-400 font-semibold text-white/90 ml-4 mr-8">burnprint</div></router-link>
            <div class="flex  text-[17px] outfit-400 text-white/90">
                <router-link to="/"  class="  pt-[14px] ml-4 lg:ml-8 font-mono text-[15px] xs:text-[18px] 	">Home</router-link>
              <a disabled href="" class="mt-[14px] ml-4 lg:ml-8 font-mono text-[15px] xs:text-[18px] text-orange-400	">Burn</a>
    
              <a href="https://www.burnprint.com/whitepaper" target="_blank" class=" mt-[14px] ml-4 lg:ml-8 font-mono text-[15px] xs:text-[18px]">Whitepaper</a>
              <div class="flex gap-2 mt-[17px] ml- lg:ml-8">
                <!-- Close (X) Icon Link -->
                <a href="https://x.com/burntoprint"  target="_blank" class=" ml-2 xs:ml-12 md:ml-12 lg:ml-0 ">
                  <img src="../assets/x.png"  width="20" height="20" alt="twitter icon">
                </a>
                 
                <a href="https://t.me/BurnToPrint"  target="_blank" class="ml-2 ">
                  <img src="../assets/telegram.webp" width="20" height="20" alt="Telegram icon">
                </a>
                <a href="https://discord.gg/mbnnES77UD"  target="_blank" class="ml-[6px] ">
                  <img src="../assets/dis.png" width="34" height="24" alt="dis icon">
                </a>
              </div>
              <!--<button class="bg-gray-600/50 text-[16px] font-semibold font-sans hover:bg-gray-500/50 text-white  py-3 px-6 rounded-full ">
                Start Burning
              </button>  -->
        </div>
      </div>
  
         <div class="hidden xl:block pt-2"> <wallet-multi-button dark ></wallet-multi-button></div>
  
  
  
    </div>
        <div class=" xl:hidden pt-8 w-full flex justify-center"><wallet-multi-button dark></wallet-multi-button></div>
  
      </div>
      <div class="flex pt-8 bg-gradient-to-r  rounded-[40px]  mt-12 fade-in-bottom ">
        
      <div v-if="showPopup"  id="walletPopup" class="wallet-popup ">
          <div class="wallet-popup-content bg-[#272932]  border-white/20 max-w-md  pb-5 mx-auto mt-52">
              <div class="flex justify-between px-4 pt-2"><h2 class="font-bold outfit-400 text-xl text-start pt-5 px-8 text-white">Connect Wallet</h2><button id="closePopup" class=" close-div  justify-end w-[36px] focus:cursor-pointer  rounded-full "><span @click="closePopup" class="close-button text-[#7f7f7f] p-1 text-[32px]">&times;</span></button></div>
              
              <h2 class="font-md text-mdl text-start pl-12  px-8  text-white/60 pt-1 outfit-400">Choose which Solana wallet you want to connect</h2>
      
              <div class="flex flex-col gap-4 border-[1px] border-gray-400/10 bg-slate-500/5 mx-8 my-8 rounded-lg pb-4 pt-4 px-4 ">
                  <button @click="connectWallet" class="w-full py-[6px] pl-4 rounded-md border-[1px] bg-transparent border-[#787878] /80    hover:bg-slate-700/20 flex justify-start gap-4 font-bold  " id="phantom"><img height="25" width="25" class="rounded-md mt-[6px]" src="../assets/phantomIcon.png" /><div class=""><p class="text-[15px] text-white  text-start ">Phantom</p><p class="text-[12px]   text-gray-500 w-">Only Supported</p></div></button>
              </div>
          </div>
      </div>
      
        <!-- Text -->
        <div v-if=" $wallet.connected.value == true" class="flex  justify-center w-full h-[70vh]">
  
          <NftList />
  
        </div>
        <div v-else class="w-full h-[70vh] text-white">
  
          <div class="flex flex-col items-center ">
            <div class="flex justify-center w-[120px] gap-3">
              <img src="../assets/tenor.gif" class="w-[55px] h-[55px] mt-[30px] mb-[10px]"   alt="ArtPiece">
              <h2 class=" text-[40px] outfit-400 leading-[48px] w-[500px] pt-8  text-center text-white">burnprint</h2>
              
              <img src="../assets/tenor.gif" class="w-[55px] h-[55px] mt-[30px] mb-[10px]"   alt="ArtPiece">
            </div>
            <h2 class="mt-12  max-w-[1000px] text-left mx-auto text-xl">Connect your wallet to start burning</h2>

  
        </div>
  
        </div>
  
      </div>
  
  
  <!--
      <div class=" max-w-xs mx-auto">
        <img src="./assets/tenor.gif" class=" w-full"   alt="ArtPiece">
        <div class="flex justify-between pl-4 pr-7 pt-2">
          <a href="https://dexscreener.com/solana/6asikp5ut8bu8j86aceeejmfcuczroxh2by48ehgrgqy"  target="_blank" class="border-[1px] border-white px-10 py-2 inline-block text-white hover:bg-white hover:text-white">
            <button class="focus:outline-none crimson-text-regular">Buy</button>
          </a>
          <a href="https://birdeye.so/token/EYUpZ1xYx3B8fdbt3wGSjAt6SeSXtUvGvkVkQPT6pNkB?chain=solana"  target="_blank" class="border-[1px] border-white px-8 py-2 inline-block text-white hover:bg-white hover:text-white">
            <button class="focus:outline-none crimson-text-regular">Charts</button>
          </a>
        </div>
      </div>-->
  
      <!--
      <h1 class="crimson-text-regular text-[56px] leading-none flex justify-center pt-32 text-white" id="tokenomics">Tokenomics</h1>
      <div class="sm:mx-auto w-full sm:w-5/6 px-4 lg:px-0 lg:w-3/5 xl:w-2/5 justify-center pb-12 text-[14px] sm:text-[19px] md:text-[21px] lg:text-[23px]" >
        <div class="flex justify-between"><h1 class="crimson-text-regular text-white leading-none flex justify-start pt-12 ">Contract Address:</h1>  <h1 class="crimson-text-regular  text-white leading-none flex justify-start pt-12 ">EYUpZ1xYx3B8fdbt3wGSjAt6SeSXtUvGvkVkQPT6pNkB</h1> </div>
        <div class="flex justify-between"><h1 class="crimson-text-regular text-white leading-none flex justify-start pt-4 ">Supply:</h1>  <h1 class="crimson-text-regular             text-white  leading-none flex justify-start pt-4 ">1 000 000 000</h1> </div>
        <div class="flex justify-between"><h1 class="crimson-text-regular text-white leading-none flex justify-start pt-4 ">LP tokens:</h1>  <h1 class="crimson-text-regular          text-white   leading-none flex justify-start pt-4 ">Burnt</h1> </div>
        <div class="flex justify-between"><h1 class="crimson-text-regular text-white leading-none flex justify-start pt-4 ">Freeze Authority:</h1>  <h1 class="crimson-text-regular   text-white   leading-none flex justify-start pt-4 ">Revoked</h1> </div>
        <div class="flex justify-between"><h1 class="crimson-text-regular text-white leading-none flex justify-start pt-4 ">Contract Ownership:</h1>  <h1 class="crimson-text-regular text-white  leading-none flex justify-start pt-4 ">Renounced</h1> </div>
      </div>
          <h1 class="crimson-text-bold text-[18px] leading-none flex justify-center pb-12">• 2024 © •SOLBURN • All Rights Reserved</h1>
  -->
  
    </div>
</div>
  </template>
  <script setup>
  import { WalletMultiButton } from "solana-wallets-vue";
  </script>
  <script>
  import NftList from './NFTList.vue';
  
  
  export default {
    name: 'BurnPage',
    
    data() {
      return {
        // State to track if the user is logged in
        isLoggedIn: false,
        isLoggedInWallet : false,
        showPopup: false,
        walletAddress : null,
      };
    },
    components: {
      NftList
    },
    methods: {
      // Method to simulate user login
  
      closePopup(){
        this.showPopup = false;
      },
      async connectWallet(){
  
  
      /*
        console.log("connectWallet");
        if ('phantom' in window) {
          const provider = window.phantom?.solana;
          const resp = await provider.connect();
          this.isLoggedIn = true;
          this.walletAddress = resp.publicKey.toString();
          this.showPopup = false
          localStorage.setItem('connected', "true");
         console.log(resp.publicKey.toString());
          if (provider?.isPhantom) {
            return provider;
          }
        }
  
        window.open('https://phantom.app/', '_blank');*/
      },
      async checkIfConnected(){
       let connected = localStorage.getItem('connected');
       if (connected == "true"){
          console.log("connectWallet");
          if ('phantom' in window) {
            const provider = window.phantom?.solana;
            const resp = await provider.connect();
            this.isLoggedIn = true;
            this.walletAddress = resp.publicKey.toString();
            this.showPopup = false
          console.log(resp.publicKey.toString());
            if (provider?.isPhantom) {
              return provider;
            }
          }
       }
      },
      
      login() {
        console.log("tried logging in ");
        //this.isLoggedIn = true;
        this.showPopup = true;
      },
      // Method to simulate user logout
      logout() {
        this.isLoggedIn = false;
        localStorage.setItem('connected', "false");
  
      }
    },
    mounted() {
  
      this.connectWallet();
    },
    
  };
  </script>
  
  <style>
  .fade-in-image { animation: fadeIn 1s; }
  .fade-in-bottom { animation: fadeOut 1s; }
  
  @keyframes fadeIn {
    0% { opacity: 0;     transform: translateY(-20px); /* Adjust the -50px value as needed */  }
  
    100% { opacity: 1; transform: translateY(0); }
  }
  
  @keyframes fadeOut {
    0% { opacity: 0;     transform: translateY(20px); /* Adjust the -50px value as needed */  }
  
    100% { opacity: 1; transform: translateY(0); }
  }
  
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #ffffff;
    margin-top: 30px;
  }
  .wallet-popup {
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .wallet-popup-content {
  
    border-radius: 0.7rem/* 8px */;
  
    text-align: center;
  }
  .outfit-300 {
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 100;
    font-style: normal;
  }
  .outfit-400 {
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }
  .outfit-500 {
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }
  .crimson-text-regular {
    font-family: "Crimson Text", serif;
    font-weight: 500;
    font-style: italic;
  }
  .crimson-text-light {
    font-family: "Crimson Text", serif;
    font-weight: 600;
    font-style: regular;
  }
  .crimson-text-bold {
    font-family: "Crimson Text", serif;
    font-weight: 300;
    font-style: normal;
  }
  .playfair-display-medium {
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }
  
  </style>
  