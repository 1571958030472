export async function LUTUA(number:any): Promise<string>{
    if (number >= 200 && number <= 220) {
        return "3ZreUZqERS4CwCEyVz5nuHoLaTLFKPw6PtHbuQjq8mAG";
    } else if (number >= 220 && number <= 243) {
        return "Gd4BuFsmZyk8n3i1dcWUrbbj8by9eoiCNhG1XLtyFCDD";
    } else if (number >= 244 && number <= 264) {
        return "HjLxxknzjoonDDEaces9aJeXihH6o7toDB1hzSfEAWEU";
    } else if (number >= 265 && number <= 289) {
        return "AtKyExeUcxY2jpSys66LTXiC8PqjyEKQAGFoa9YPRtAG";
    } else if (number >= 290 && number <= 317) {
        return "2KUKNP13H9D5FXtnHeyWJ9uNLFhTsKf5NXpXJ13RQ8xN";
    } else if (number >= 318 && number <= 351) {
        return "7RfFdDTwSfZywgoWJyaskFSq8WdmcP7UiioyS9Dawon1";
    } else if (number >= 352 && number <= 391) {
        return "HRnfjdS5ERNp6jVTsVifMo9Q83Gq2EMfVx3GDLi8R2Zx";
    } else if (number >= 392 && number <= 433) {
        return "bLqyHBhz7mRGq6rGZnNBhQCZzgS96HVpdPWKcxqGzPa";
    } else if (number >= 434 && number <= 486) {
        return "CFo2RxKMAwuzUmJ1yb3UYUqrPSAZTbbWHKeVkXATrycj";
    } else if (number >= 487 && number <= 540) {
        return "GpBqWYGaVbyX5tYm7UYUWwsfSWE66PYPfpcyScXgLrLr";
    } else if (number >= 541 && number <= 600) {
        return "F89zW9K2meyB5yw1X5UqF1fkUVXBXxtEmFKKD1BYJV7J";
    } else if (number >= 601 && number <= 666) {
        return "SVFVLUayX9icMhDsB7ot9aY4PvZTUrMwHyYyxKtWb3j";
    } else if (number >= 667 && number <= 750) {
        return "2uBfoEunbEGGmSaNvUPqQZHxcj2jhKQEpb4ua3BttjkP";
    } else if (number >= 751 && number <= 857) {
        return "EVxam3JP5WQT4CKoDYvdBtRyoANhoGVfe85Gh82Qg42L";
    } else if (number >= 858 && number <= 970) {
        return "32yXuGm2MCBzAhky9FshsaKBzahZYmvsgTiRPC8uj4wZ";
    } else if (number >= 971 && number <= 1102) {
        return "GFgpVY7n7Mq6B59L4yuUxCZvpeWMSmUVAJBVK7KxL4Mv";
    } else if (number >= 1103 && number <= 1304) {
        return "FdeAWVVqaRLT5S3nz1gNmH2PnW59Vyyz1EVLaACPr5sK";
    } else if (number >= 1308 && number <= 1601) {
        return "kdxNQPSHCMajCEF3wZrwE7AYjyRPc57cdCuxSMYhdUf";
    } else if (number >= 1603 && number <= 1939) {
        return "GJrLFYdqu6RTAFCdVBLA1hAeC5CoGWpswCZqckjyfBDM";
    } else if (number >= 1940 && number <= 2440) {
        return "8X2qv1tddonADj7i9ektLqWBUeKr9qzBx5CHt5rcWhDo";
    } else if (number >= 2441 && number <= 3207) {
        return "D1wxboya2FPT3tHxPebraVwbyuUnBvKykn3VWq6wCrHZ";
    } else if (number >= 3216 && number <= 4387) {
        return "EmVmNg6fvHSjxMGBpkA8HwGBhWzZvXWsvp5zZC2BLVYT";
    } else if (number >= 4389 && number <= 6894) {
        return "9Y9UewbBH3BZ1LHPZU6Bc4frNdwT1v7NA5mcH1Qj7Dmv";
    } else if (number >= 6901 && number <= 992701) {
        return "BMGaDGof1nMkRwPG11sAkQ2ccz1M39txVvzKsqeTQCrz";
    } else {
        return "Number out of range";
    }
}
export function getLUTColl(value:any) {
    if (value >= 200 && value <= 239) {
        return "HSygFLCz5uUkpLZt4vghRgBeeqUHvTdgMrQ75iLfEyUU";
    } else if (value >= 240 && value <= 288) {
        return "76zzaNAJvsETGoCBTFgY9uw6VEw6rgjanhAryB5sTLcA";
    } else if (value >= 289 && value <= 335) {
        return "8N62aTF2BZE75Szaryfi2z86wMu85g38AaaptEb1B9ye";
    } else if (value >= 336 && value <= 410) {
        return "DWNQJasZ4BK25RRCJhD4p5GT9p2nHXSTY9ZacC7geC2M";
    } else if (value >= 411 && value <= 491) {
        return "BLdwx6JwEHF3thk1cA7aCbDWZ1S8zMLv8djCy1HQ8wjr";
    } else if (value >= 492 && value <= 584) {
        return "5GwQm9ZKvtyjA5BcwPCdwMr6ZSuYQpLLvcZeie1yNEV8";
    } else if (value >= 585 && value <= 715) {
        return "579Uc3gprLGbS4fcxEvwaMjmE5A3wdkkbVGjs28auVU1";
    } else if (value >= 717 && value <= 868) {
        return "9n8HRtzA6WRs2LQ1xFBzh7T1BwRGpBvvpWJrf1PQ8s99";
    } else if (value >= 869 && value <= 1000) {
        return "7AwQudrFfMNJWFkxn823qpYdJjTYUFNrtCExDTFLT8KT";
    } else if (value == 1001) {
        return "5f5m13pG7zVeCv1VmxqVnMQteBnAXrhABQtJ15rwzVUd";
    } else if (value >= 1002 && value <= 1248) {
        return "tgPi3Q7vs6CoStRsVBZf49uwLPy74RN5WDp8NUmBBAi";
    } else if (value >= 1250 && value <= 1619) {
        return "Et2HmA8qRH1aPUcQ4AyUa4f1cqXST6JeBovaHN1tmDfS";
    } else if (value >= 1621 && value <= 2068) {
        return "2YqTtSjTyL8HxNyth1zJ3iny5rwkcRppvJDXHd2JhX7c";
    } else if (value >= 2072 && value <= 2712) {
        return "4amNV6bjST5YwUKwvWBaXV8U9V8YDwoB5PcephzY6Raq";
    } else if (value >= 2714 && value <= 3761) {
        return "3J1r949GnVtmyigCcop25XvQEGnpB4ku611HM71uhCQg";
    } else if (value >= 3768 && value <= 5530) {
        return "4LpFRcDweHuLe1MyB5Feq22YYAu89swgWWzAG6WX9ZQ5";
    } else if (value >= 5537 && value <= 9999) {
        return "EbaJ2Zb2NoJAuABzc48ckJrTiDFuKn1kauwbzm1NEHgx";
    } else if (value >= 10000 && value <= 239994) {
        return "HYCW7xbi15PiAGnqKk2HZpWUSCmG3Ep3rxDjAGfSu357";
    } else {
        return "Value out of range";
    }
}
