import { PublicKey } from '@solana/web3.js';
import BN from 'bn.js';
export { TokenStandard } from '@metaplex-foundation/mpl-token-metadata';
import {toBufferLE} from 'bigint-buffer';

export const TMETA_PROGRAM_ID = new PublicKey(
  'metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s',
);

export const findMetadataPda = (
  mint: PublicKey,
  programId: PublicKey = TMETA_PROGRAM_ID,
) => {
  return PublicKey.findProgramAddressSync(
    [Buffer.from('metadata', 'utf8'), programId.toBuffer(), mint.toBuffer()],
    programId,
  );
};

export const findMasterEditionPda = (
  mint: PublicKey,
  programId: PublicKey = TMETA_PROGRAM_ID,
) => {
  return PublicKey.findProgramAddressSync(
    [
      Buffer.from('metadata', 'utf8'),
      programId.toBuffer(),
      mint.toBuffer(),
      Buffer.from('edition', 'utf8'),
    ],
    programId,
  );
};

export const findEditionPda = (
  mint: PublicKey,
  programId: PublicKey = TMETA_PROGRAM_ID,
) => {
  return PublicKey.findProgramAddressSync(
    [
      Buffer.from('metadata', 'utf8'),
      programId.toBuffer(),
      mint.toBuffer(),
      Buffer.from('edition', 'utf8'),
    ],
    programId,
  );
};

export const findEditionMarkerPda = (
  mint: PublicKey,
  edition: BN,
  programId: PublicKey = TMETA_PROGRAM_ID,
) => {
  return PublicKey.findProgramAddressSync(
    [
      Buffer.from('metadata', 'utf8'),
      programId.toBuffer(),
      mint.toBuffer(),
      Buffer.from('edition', 'utf8'),
      Buffer.from(edition.div(new BN(248)).toString()),
    ],
    programId,
  );
};

export const findCollectionAuthorityRecordPda = (
  mint: PublicKey,
  collectionAuthority: PublicKey,
  programId: PublicKey = TMETA_PROGRAM_ID,
) => {
  return PublicKey.findProgramAddressSync(
    [
      Buffer.from('metadata', 'utf8'),
      programId.toBuffer(),
      mint.toBuffer(),
      Buffer.from('collection_authority', 'utf8'),
      collectionAuthority.toBuffer(),
    ],
    programId,
  );
};

export const findUseAuthorityRecordPda = (
  mint: PublicKey,
  useAuthority: PublicKey,
  programId: PublicKey = TMETA_PROGRAM_ID,
) => {
  return PublicKey.findProgramAddressSync(
    [
      Buffer.from('metadata', 'utf8'),
      programId.toBuffer(),
      mint.toBuffer(),
      Buffer.from('user', 'utf8'),
      useAuthority.toBuffer(),
    ],
    programId,
  );
};

export const findProgramAsBurnerPda = (
  programId: PublicKey = TMETA_PROGRAM_ID,
) => {
  return PublicKey.findProgramAddressSync(
    [
      Buffer.from('metadata', 'utf8'),
      programId.toBuffer(),
      Buffer.from('burn', 'utf8'),
    ],
    programId,
  );
};

export const AUTH_PROGRAM_ID = new PublicKey(
  'auth9SigNpDKz4sJJ1DfCTuZrZNSAgh9sFD3rboVmgg',
);

export const findTokenRecordPda = (mint: PublicKey, token: PublicKey) => {
  return PublicKey.findProgramAddressSync(
    [
      Buffer.from('metadata'),
      TMETA_PROGRAM_ID.toBuffer(),
      mint.toBuffer(),
      Buffer.from('token_record'),
      token.toBuffer(),
    ],
    TMETA_PROGRAM_ID,
  );
};

export const findRuleSetPda = (payer: PublicKey, name: string) => {
  return PublicKey.findProgramAddressSync(
    [Buffer.from('rule_set'), payer.toBuffer(), Buffer.from(name)],
    AUTH_PROGRAM_ID,
  );
};
export const TOKEN_PROGRAM_ID = new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA');
export const ASSOCIATED_TOKEN_PROGRAM_ID = new PublicKey('ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL');

export function getAssociatedTokenAddressSync(
    mint: PublicKey,
    owner: PublicKey,
    allowOwnerOffCurve = false,
    programId = TOKEN_PROGRAM_ID,
    associatedTokenProgramId = ASSOCIATED_TOKEN_PROGRAM_ID
): PublicKey {
    if (!allowOwnerOffCurve && !PublicKey.isOnCurve(owner.toBuffer())) throw new Error();

    const [address] = PublicKey.findProgramAddressSync(
        [owner.toBuffer(), programId.toBuffer(), mint.toBuffer()],
        associatedTokenProgramId
    );

    return address;
}

export const findTokenAccount = async (mint: PublicKey, owner: PublicKey) => {
  return await getAssociatedTokenAddressSync(
    mint,
    owner,
    
  );
}


/*function main() {

  //example
  console.log("here")
  const collection = new PublicKey("ACD9bHPZEqHNuiMHiqD2jW8tcnr9Q53gV5gP64s3CR8D");
  console.log("1")
  const mint = new PublicKey("EuiWxgnRkEAZ1cxkyj3Y21hc4FjgbHswrjoQHVvVr2QP");
  console.log("2")
  const owner = new PublicKey("EVbisxW8UuJFEYpiek6b33aW2guz3DEkHZPUYMB5dd1Y");
  console.log("3")


  const collection_metadata = findMetadataPda(collection);
  const mint_metadata = findMetadataPda(mint);
  const ata = findTokenAccount(mint, owner)

  console.log(collection_metadata, mint_metadata, ata);
}*/